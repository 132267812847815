@import "@/styles/_variables.scss";




























































































































































.uk-dropdown {
  min-width: 150px;
}
